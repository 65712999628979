/**
 * 主设备
 */
 import * as echarts from 'echarts'
 import deviceTypeJs from "./device-type";
 import deviceModelJs from "./device-model";
 import deviceMapJs from "./device-map";
 import session from "../../../../store/store";
export default {

    data() {
        return {
           type:0, //1:全屏
           leftTitle:'',
           centerTitle:'',
        };
    },
    mounted() {
      this.init();
      if(this.$route.query.type != null) {
        this.type = this.$route.query.type;
        document.title = session.getSysName() + '-' + '大数据中心'
      }

      if(0 == this.type) {
        this.leftTitle = '';
        this.centerTitle = '设 备 报 表';
      }else if(1 == this.type) {
        this.leftTitle = '设备报表';
        this.centerTitle = '大 数 据 中 心';
      }
    },
    methods: {
      init() {
        deviceTypeJs.setDeviceTypeChart(echarts);
        deviceModelJs.setDeviceModelChart(echarts);
        deviceMapJs.initMap(echarts);
      },
      magnify() {
        const routeData = this.$router.resolve({
            path: "/device-report-full-screen",
            query: {
                type: 1     //全屏
             } 
          })
        window.open(routeData.href)
      }
    }
};