
import { getDistrict } from '../../../../api/api'
var _map;
var _mapData = []
var _myChart = null;
var _mapJson = null;
var _option = null;
var _cityName = '中国';
var _echarts = null;


export default {
    initMap(echarts) {
        _echarts = echarts;
        this.initEcharts(echarts);
        _cityName = '雅安市';
        this.getDistrict(_cityName, '511800');
    },
    initEcharts(echarts) {
        const doc = document.getElementById("mapType")
        _myChart = echarts.getInstanceByDom(doc);
        if (_myChart == undefined) {
            _myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        _myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
    },

    /**
 * 获取行政区数据
 */
    getDistrict(keywords, adcode) {
        let params = {
            keywords: keywords,
        };
        getDistrict(params).then((res) => {
            const data = res.data.districts;
            data.forEach((item) => {
                this.getDataEx(item.districts, adcode)
            });
        });
    },
    getDataEx(districts, adcode) {
        if (districts) {
            _mapData = [];
            var districtValue = [3, 10000, 50, 300, 450,30,700];
            for (var i = 0, l = districts.length; i < l; i++) {
                var name = districts[i].name;
                var cityCode = districts[i].adcode;
                var value = districtValue[this.getRandomNum(0, 6)];
                _mapData.push({
                    name: name,
                    value: value,//Math.random() * 100
                    cityCode: cityCode
                });
            }
            this.loadMapData(adcode);
        }
    },
    getRandomNum(Min, Max) {
        var Range = Max - Min;
        var Rand = Math.random();
        return (Min + Math.round(Rand * Range));
    },
    loadMapData(adcode) {
        if (_mapJson != null) {
            _myChart.setOption(_option);
        } else {
            AMapUI.loadUI(['geo/DistrictExplorer'], (DistrictExplorer) => {
                //创建一个实例
                var districtExplorer = window.districtExplorer = new DistrictExplorer({
                    eventSupport: true, //打开事件支持
                    map: _map
                });
                districtExplorer.loadAreaNode(adcode, (error, areaNode) => {
                    if (error) {
                        console.error(error);
                        return;
                    }
                    _mapJson = {};
                    _mapJson.features = areaNode.getSubFeatures();
                    if (0 == _mapJson.features.length) {
                        _mapJson = {};
                        _mapJson.features = [].concat(areaNode.getParentFeature(adcode));
                    }

                    this.loadMap(_cityName, _mapJson);
                });
            });
        }
    },
    // var colorValue = ['#0a5abd','#2444ba','#1432a3','#1a3595','#172d7b'];

    loadMap(mapName, mapData) {
        if (mapData) {
            _echarts.registerMap(mapName, mapData);
            _option = {
                // backgroundColor: '#0e1d4f',
                title: {
                    text: _cityName,
                    textStyle: {
                        color: '#fff',
                        fontSize:20,
                    },
                    left:20
                  },
                visualMap: [{
                    min: 10,
                    max: 1000,
                    text: ['高', '低'],
                    realtime: false,
                    calculable: true,
                    left:20,
                    inRange: {
                        color: ['lightyellow', 'yellow', 'orangered']
                    },
                    textStyle: {
                        color: '#fff'
                    }
                }],
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}<br/>{c}'
                },
                geo: {
                    show: true,
                    map: mapName,
                    label: {
                        normal: {
                            show: true,
                            // textStyle: {
                            //     color: '#fff'
                            // }
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                color: '#fff'
                            }
                        }
                    },
                    roam: false,//是否开启鼠标缩放和平移漫游
                    zoom: 1.2, //当前视角的缩放比例。
                    itemStyle: {
                        // normal: {
                        //     areaColor: '#1432a3',
                        //     borderColor: '#afd3f8',
                        //     borderWidth: 1.1,
                        // },
                        emphasis: {
                            areaColor: '#1432a3',
                            borderColor: '#afd3f8',
                            label: {
                                show: true,
                                textStyle: {
                                    color: '#fff'
                                }
                            }
                        }
                    },
                },
                series: [
                    {
                        type: 'map',
                        // roam: false,
                        name: mapName,
                        geoIndex: 0,
                        data: _mapData,//设备每个区的数量
                    }]
            };
            _myChart.setOption(_option);
        }
    },

};