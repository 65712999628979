/**
 * 设备类型
 */


export default {
    setDeviceTypeOption(echarts, option) {
        const doc = document.getElementById("deviceType")
        let myChart = echarts.getInstanceByDom(doc);
        if (myChart == undefined) {
            myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
        myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
    },

    setDeviceTypeChart(echarts) {
        var option = {
            color: ['#35EFE0', '#0075FD', '#9383F6', '#FF9452'],//配置颜色
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b}: {c} ({d}%)",
                position: [10, 1],
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                itemWidth:10,
                itemHeight:10,
                textStyle: {
                    color: '#fff',
                },
                itemStyle:{
                    borderWidth:0,
                },
            },
            series: [
                {
                    name: '设备类型',
                    type: 'pie',
                    radius: ['10%', '80%'],
                    // avoidLabelOverlap: false,
                    // selectedOffset : -5,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        // show: false,
                        // position: 'center',
                        formatter: '{d}%',//设置左边图上的%
                        position: 'inner',
                        fontSize: 14,
                        color: '#FFFFFF'
                    },
                    // emphasis: {
                    //     label: {
                    //         show: true,
                    //         fontSize: '40',
                    //         fontWeight: 'bold'
                    //     }
                    // },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: [
                        { value: 1500, name: '智能床垫' },
                        { value: 700, name: '穿戴设备' },
                        { value: 500, name: '健康一体机' },
                        { value: 300, name: '智能家居' },
                    ]
                }
            ]
        };

        this.setDeviceTypeOption(echarts, option);
    },
};