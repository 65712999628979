<template>
    <div class="page">
        <!--顶部标题-->
        <div class="top-title">
            <div style="float: left; width: 20%">
                <div style="margin-left: 16px; margin-top: 11px">
                    <span class="topspan">{{leftTitle}}</span>
                </div>
            </div>
            <div style="width: 60%; float: left; text-align: center">
                <div style="margin-top: 9px">
                    <span class="text-gradient">{{centerTitle}}</span>
                </div>
            </div>

            <div style="float: right; width: 20%">
                <div style="margin-top: 13px;">
                    <div class="secondspan" v-if="this.type == 0">
                        <img src="../../../assets/img/fullscreen.png" @click="magnify()"/>
                    </div>
                </div>
            </div>
        </div>

      


        <!--数据概览统计-->
        <div class="top-box">
            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">设备总数</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">1000</div>
                </div>
            </div>

            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">在线</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">600</div>
                </div>
            </div>

            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">离线</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">400</div>
                </div>
            </div>

            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">正常</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">300</div>
                </div>
            </div>

            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">异常</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">400</div>
                </div>
            </div>
            <div class="top-text-box">
                <div style="padding-top: 15px;">
                    <div style="color: #00CFF3;font-size: 18px;text-align: center;font-weight: bold;">报警</div>
                    <div style="color: #FFFFFF;font-size: 16px;margin-top: 20px;text-align: center;">100</div>
                </div>
            </div>
        </div>

        <!--数据模块-->
        <div class="data-box">
            <div style="width:40%;height: 100%;margin-left: 10px;">
                 <!--设备型号TOP5模块-->
                 <div class="data-box1">
                    <div class="data-left-box">
                        <div style="color:#FFFFFF;font-size:18px;position: relative;top:-3px;left:10px;float: left;">设备型号TOP5</div>
                        <div id="deviceModelType" class="pie-style"></div>
                    </div>
                </div>

                 <!--设备类型模块-->
                 <div class="data-box1">
                    <div class="data-left-box">
                        <div style="color:#FFFFFF;font-size:18px;position: relative;top:-3px;left:10px;float: left;">设备类型比例</div>
                        <div id="deviceType" class="pie-style"></div>
                    </div>
                </div>
            </div>
            <div style="width:60%;height: 100%;margin-left: 10px;">
                <div id="mapType" style="width: 100%;height: 85%;margin-top: 0px;"></div>
            </div>

        </div>
    </div>
</template>

<script src='./js/device-report.js'></script>
<style scoped src="./css/device-report.css"></style>